.three_d_section_full {
  position: relative;
  height: 90vh;
  width: 100%;
  margin-bottom: 3% !important;
  background: white;

  .potree_container {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
  }

  img.annotation-img {
    width: 100%;
    height: 100%;
    margin-top: 3px;
  }

  #sldSize {
    width: 125px;
  }

  #menu_about, #potree_branding {
    display: none;
  }

  .btn-check-left {
    float: left;
    width: 20%;
  }

  .btn-check-right {
    float: right;
    width: 20%;
  }

  .btn-checkbox {
    display: none;
  }

  .btn-not-checked, .btn-checked {
    display: inline-block;
    width: 100%;
    height: 19px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    cursor: pointer;
  }

  .btn-not-available {
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 19px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    font-weight: bold;
  }

  .button-icon {
    text-decoration: none;
    background: none;
    border: none;

    /* color: white;
     */
    cursor: pointer;
    height: 32px;
    filter: drop-shadow(0px 0px 3px rgba(187, 190, 221, 0.4));

    &:hover {
      /*background-color: #09181C;
      */
      filter: drop-shadow(0px 0px 4px white);
    }
  }

  .sliderRange * {
    border-radius: 5px !important;
  }

  .pv-menu-list {
    list-style-type: none;
    padding: 0;
    margin: 15px 0px;

    /* overflow: hidden;
     */

    > * {
      margin: 4px 16px;
    }
  }

  .display {
    display: block !important;
  }

  .sphereButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem !important;
    height: 100%;
    display: block;
    overflow-y: auto;
    border: 1px solid #d5d5ea;
    background-color: #fff;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f0f5ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #091932;
    }
  }

  .submit-button {
    width: 100%;
  }

  .damages-menu-list {
    margin: 15px 0px;
  }

  #sidebar_root {
    padding: 2px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
  }

  #potree_sidebar_container {
    position: absolute;
    z-index: 0;
    width: 350px;
    height: 100%;
    overflow-y: auto;
    font-size: 85%;
    border: 1px solid #d5d5ea;
    background-color: #fff;
    overflow-x: hidden;

    a {
      color: #091932;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f0f5ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #091932;
    }
  }

  .accordion > h3 {
    background-color: #091932 !important;
    background: #f6f6f6 50% 50% repeat-x;
    border: 1px solid #d5d5ea;
    color: #fff;
    cursor: pointer;
    margin: 1px 0 0 0;
    padding: 10px 10px 10px 24px;
    font-size: 1rem;
    font-weight: normal;

    &:hover {
      filter: brightness(150%);
    }
  }

  .accordion-content {
    padding: 0px 0px !important;
    border: none !important;
  }

  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;

    /* Safari */
    animation: spin 2s linear infinite;
    z-index: 100000000;
  }

  .filter {
    border: 1px solid #d5d5ea;
    background-color: #e8e8f0;
    border-radius: 5px;
    padding: 5px;
    margin: 10px 15px !important;
  }
}

.overview-grid {

  .potree_menu_toggle {
    display: none;
  }

  .three_d_section_full {
    height: 320px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}