.validation-tool-container .react-transform-component {
    overflow: visible !important;
}

.validation-tool-container .hObiiS {
    border: solid 3px red !important;
}

.validation-tool-container .overflowContainer {
    overflow: hidden !important;
}